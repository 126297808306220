/*=== MEDIA QUERY ===*/
@import url(http://fonts.googleapis.com/css?family=Montserrat:400,700|Poppins:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:300,400,500,600,700);
html {
  overflow-x: hidden;
}

body {
  line-height: 1.5;
  font-family: "Hind", serif;
  -webkit-font-smoothing: antialiased;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.65);
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #242424;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 42px;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.3rem;
  line-height: 30px;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  line-height: 30px;
}

.navbar-toggle .icon-bar {
  background: #dc2b1c;
}

input[type="email"], input[type="password"], input[type="text"], input[type="tel"] {
  box-shadow: none;
  height: 45px;
  outline: none;
  font-size: 14px;
}

input[type="email"]:focus, input[type="password"]:focus, input[type="text"]:focus, input[type="tel"]:focus {
  box-shadow: none;
  border: 1px solid #dc2b1c;
}

.form-control {
  box-shadow: none;
  border-radius: 0;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #dc2b1c;
}

.py-7 {
  padding: 7rem 0px;
}

.btn {
  display: inline-block;
  font-size: 14px;
  font-size: 0.8125rem;
  font-weight: 400;
  padding: 1rem 2.5rem;
  text-transform: uppercase;
  border-radius: 0;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn.btn-icon i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.btn:focus {
  outline: 0px;
  border: 0px;
  box-shadow: none;
}

.btn-main, .btn-transparent, .btn-small {
  background: #dc2b1c;
  color: #fff;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn-main:hover, .btn-transparent:hover, .btn-small:hover {
  background: #dd0b0b;
  color: #fff;
}

.btn-solid-border {
  border: 2px solid #dc2b1c;
  background: transparent;
  color: #242424;
}

.btn-solid-border:hover {
  border: 2px solid #dc2b1c;
  background: #dc2b1c;
}

.btn-transparent {
  background: transparent;
  padding: 0;
  color: #dc2b1c;
}

.btn-transparent:hover {
  background: transparent;
  color: #dc2b1c;
}

.btn-large {
  padding: 20px 45px;
}

.btn-large.btn-icon i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.btn-small {
  padding: 10px 25px;
  font-size: 12px;
}

.btn-round {
  border-radius: 4px;
}

.btn-round-full {
  border-radius: 50px;
}

.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}

.bg-gray {
  background: #f5f8f9;
}

.bg-primary {
  background: #dc2b1c;
}

.bg-primary-dark {
  background: #f52626;
}

.bg-primary-darker {
  background: #dd0b0b;
}

.bg-dark {
  background: #242424;
}

.bg-gradient {
  background-image: linear-gradient(145deg, rgba(19, 177, 205, 0.95) 0%, rgba(152, 119, 234, 0.95) 100%);
  background-repeat: repeat-x;
}

.section {
  padding: 100px 0;
}

.section-sm {
  padding: 70px 0;
}

.section-title {
  margin-bottom: 70px;
}

.section-title .title {
  font-size: 50px;
  line-height: 50px;
}

.section-title p {
  color: #666;
  font-family: "Poppins", sans-serif;
}

.subtitle {
  color: #dc2b1c;
  font-size: 14px;
  letter-spacing: 1px;
}

.overly, .page-title, .slider, .cta, .hero-img {
  position: relative;
}

.overly:before, .page-title:before, .slider:before, .cta:before, .hero-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
}

.overly-2, .bg-counter, .cta-block, .latest-blog {
  position: relative;
}

.overly-2:before, .bg-counter:before, .cta-block:before, .latest-blog:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.text-color {
  color: #dc2b1c;
}

.text-black {
  color: #242424;
}

.text-color2 {
  color: #c54041;
}

.text-color2 {
  color: #b99769;
}

.text-sm {
  font-size: 14px;
}

.text-md {
  font-size: 2.25rem;
}

.text-lg {
  font-size: 3.75rem;
}

.no-spacing {
  letter-spacing: 0px;
}

/* Links */
a {
  color: #242424;
  text-decoration: none;
}

a:focus, a:hover {
  color: #dc2b1c;
  text-decoration: none;
}

a:focus {
  outline: none;
}

.content-title {
  font-size: 40px;
  line-height: 50px;
}

.page-title {
  padding: 100px 0;
}

.page-title .block h1 {
  color: #fff;
}

.page-title .block p {
  color: #fff;
}

.page-wrapper {
  padding: 70px 0;
}

#wrapper-work {
  overflow: hidden;
  padding-top: 100px;
}

#wrapper-work ul li {
  width: 50%;
  float: left;
  position: relative;
}

#wrapper-work ul li img {
  width: 100%;
  height: 100%;
}

#wrapper-work ul li .items-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  padding-left: 44px;
  padding-top: 140px;
}

#wrapper-work ul li .items-text h2 {
  padding-bottom: 28px;
  padding-top: 75px;
  position: relative;
}

#wrapper-work ul li .items-text h2:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75px;
  height: 3px;
  background: #fff;
}

#wrapper-work ul li .items-text p {
  padding-top: 30px;
  font-size: 16px;
  line-height: 27px;
  font-weight: 300;
  padding-right: 80px;
}

/*--
	features-work Start 
--*/
#features-work {
  padding-top: 50px;
  padding-bottom: 75px;
}

#features-work .block ul li {
  width: 19%;
  text-align: center;
  display: inline-block;
  padding: 40px 0px;
}

#navbar {
  background: #222328;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

#navbar li {
  padding-left: 15px;
}

#navbar .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: .5px;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

#navbar .nav-link:hover, #navbar .nav-link:focus {
  color: #dc2b1c;
}

#navbar .btn {
  padding: .5rem 1.5rem;
  color: #fff;
}

.header-top {
  background: #222328;
  color: #919194;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.header-top .header-top-socials {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  padding: 12px 0px;
}

.header-top .header-top-socials a {
  color: #919194;
  margin-right: 15px;
  font-size: 16px;
}

.header-top .header-top-info {
  color: #919194;
  font-size: 16px;
}

.header-top .header-top-info a span {
  color: #fff;
}

.header-top .header-top-info a {
  margin-left: 35px;
  color: #919194;
}

.navbar-brand {
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
}

.navbar-brand span {
  color: #dc2b1c;
}

.dropdown-menu {
  visibility: hidden;
  filter: alpha(opacity=0);
  opacity: 0;
  transition: all .2s ease-in, visibility 0s linear .2s, transform .2s linear;
  -webkit-transition: all .2s ease-in, visibility 0s linear .2s, -webkit-transform .2s linear;
  -o-transition: all .2s ease-in, visibility 0s linear .2s, -o-transform .2s linear;
  -ms-transition: all .2s ease-in, visibility 0s linear .2s, -ms-transform .2s linear;
  width: 250px;
  margin-top: 15px;
  padding: 0px;
  border-radius: 0px;
  display: block;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown:hover .dropdown-menu {
  visibility: visible;
  -webkit-transition: all .45s ease 0s;
  -moz-transition: all .45s ease 0s;
  -ms-transition: all .45s ease 0s;
  -o-transition: all .45s ease 0s;
  transition: all .45s ease 0s;
  opacity: 1;
}

.dropdown-item {
  padding: .8rem 1.5rem;
  text-transform: uppercase;
  font-size: 14px;
}

.dropdown-item:hover {
  background: #dc2b1c;
  color: #fff;
}

ul.dropdown-menu li {
  padding-left: 0px !important;
}

.bg-1 {
  background: url("../images/bg/home-2.jpg") no-repeat 50% 50%;
  background-size: cover;
}

.bg-2 {
  background: url("../images/bg/home-5.jpg");
  background-size: cover;
}

.slider {
  background: url("../images/bg/home-1.jpg") no-repeat;
  background-size: cover;
  background-position: 10% 0%;
  padding: 200px 0 280px 0;
  position: relative;
}

.slider .block h1 {
  font-size: 70px;
  line-height: 80px;
  font-weight: 600;
  color: #fff;
}

.slider .block p {
  margin-bottom: 30px;
  color: #b9b9b9;
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
}

.slider .block span {
  letter-spacing: 1px;
}

.intro-item i {
  font-size: 60px;
  line-height: 60px;
}

.color-one {
  color: #dc2b1c;
}

.color-two {
  color: #00d747;
}

.color-three {
  color: #9262ff;
}

.color-four {
  color: #088ed3;
}

.bg-about {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 45%;
  min-height: 650px;
  background: url("../images/about/home-8.jpg") no-repeat;
  background-size: cover;
}

.about-content {
  padding: 20px 0px 0px 80px;
}

.about-content h4 {
  font-weight: 600;
}

.about-content h4:before {
  position: absolute;
  content: "\f576";
  font-family: "Font Awesome 5 Free";
  font-size: 30px;
  position: absolute;
  top: 8px;
  left: -65px;
  font-weight: 700;
}

.counter-item .counter-stat {
  font-size: 50px;
}

.counter-item p {
  margin-bottom: 0px;
}

.bg-counter {
  background: url("../images/bg/counter.jpg") no-repeat;
  background-size: cover;
}

.team-img-hover .team-social li a.facebook {
  background: #6666cc;
}

.team-img-hover .team-social li a.twitter {
  background: #3399cc;
}

.team-img-hover .team-social li a.instagram {
  background: #cc66cc;
}

.team-img-hover .team-social li a.linkedin {
  background: #3399cc;
}

.team-img-hover {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

.team-img-hover li a {
  display: inline-block;
  color: #fff;
  width: 50px;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  border: 2px solid transparent;
  border-radius: 2px;
  text-align: center;
  transform: translateY(0);
  backface-visibility: hidden;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.team-img-hover:hover li a:hover {
  transform: translateY(4px);
}

.team-item:hover .team-img-hover {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.service-item {
  position: relative;
  padding-left: 80px;
}

.service-item i {
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 50px;
  opacity: .4;
}

.cta {
  background: url("../images/bg/home-3.jpg") fixed 50% 50%;
  background-size: cover;
  padding: 120px 0px;
}

.cta-block {
  background: url("../images/bg/home-3.jpg") no-repeat;
  background-size: cover;
}

.testimonial-item {
  padding: 50px 30px;
}

.testimonial-item i {
  font-size: 40px;
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 1;
}

.testimonial-item .testimonial-text {
  font-size: 20px;
  line-height: 38px;
  color: #242424;
  margin-bottom: 30px;
  font-style: italic;
}

.testimonial-item .testimonial-item-content {
  padding-left: 65px;
}

.slick-slide:focus, .slick-slide a {
  outline: none;
}

.hero-img {
  background: url("../images/bg/home-5.jpg");
  position: absolute;
  content: "";
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0px;
}

.h70 {
  height: 55%;
}

.lh-45 {
  line-height: 45px;
}

.pricing-header h1 {
  font-size: 70px;
  font-weight: 300;
}

.pricing .btn-solid-border {
  border-color: #dedede;
}

.pricing .btn-solid-border:Hover {
  border-color: #dc2b1c;
}

.portflio-item .portfolio-item-content {
  position: absolute;
  content: "";
  right: 0px;
  bottom: 0px;
  opacity: 0;
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
}

.portflio-item:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
  overflow: hidden;
}

.portflio-item:hover:before {
  opacity: 1;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .overlay-item {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #dc2b1c;
  opacity: 0;
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
}

.portflio-item:hover .overlay-item {
  opacity: 1;
}

.contact-form-wrap .form-group {
  padding-bottom: 15px;
  margin: 0px;
}

.contact-form-wrap .form-group .form-control {
  background: #f5f8f9;
  height: 48px;
  border: 1px solid #EEF2F6;
  box-shadow: none;
  width: 100%;
}

.contact-form-wrap .form-group-2 {
  margin-bottom: 13px;
}

.contact-form-wrap .form-group-2 textarea {
  background: #f5f8f9;
  height: 135px;
  border: 1px solid #EEF2F6;
  box-shadow: none;
  width: 100%;
}

.address-block li {
  margin-bottom: 10px;
}

.address-block li i {
  font-size: 20px;
  width: 20px;
}

.social-icons li {
  margin: 0 6px;
}

.social-icons i {
  margin-right: 15px;
  font-size: 25px;
}

.google-map {
  position: relative;
}

.google-map #map {
  width: 100%;
  height: 450px;
}

/*=================================================================
  Latest Posts
==================================================================*/
.blog-item-content h3 {
  line-height: 36px;
}

.blog-item-content h3 a {
  transition: all .4s ease 0s;
}

.blog-item-content h3 a:hover {
  color: #dc2b1c !important;
}

.lh-36 {
  line-height: 36px;
}

.tags a {
  background: #f5f8f9;
  display: inline-block;
  padding: 8px 23px;
  border-radius: 38px;
  margin-bottom: 10px;
  border: 1px solid #eee;
  font-size: 14px;
  text-transform: capitalize;
}

.pagination .nav-links a, .pagination .nav-links span.current {
  font-size: 20px;
  font-weight: 500;
  color: #c9c9c9;
  margin: 0 10px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.pagination .nav-links span.current, .pagination .nav-links a.next, .pagination .nav-links a.prev {
  color: #242424;
}

h3.quote {
  font-size: 25px;
  line-height: 50px;
  font-weight: normal;
  padding: 0px 25px 0px 85px;
  margin: 65px 0 65px 0 !important;
  position: relative;
}

h3.quote::before {
  content: '';
  width: 55px;
  height: 2px;
  background: #dc2b1c;
  position: absolute;
  top: 25px;
  left: 0;
}

.nav-posts-title {
  line-height: 25px;
  font-size: 18px;
}

.latest-blog {
  position: relative;
  padding-bottom: 150px;
}

.mt-70 {
  margin-top: -70px;
}

.border-1 {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.blog-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

/*=================================================================
  Single Blog Page
==================================================================*/
.post.post-single {
  border: none;
}

.post.post-single .post-thumb {
  margin-top: 30px;
}

.post-sub-heading {
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
}

.post-social-share {
  margin-bottom: 50px;
}

.post-comments {
  margin: 30px 0;
}

.post-comments .media {
  margin-top: 20px;
}

.post-comments .media > .pull-left {
  padding-right: 20px;
}

.post-comments .comment-author {
  margin-top: 0;
  margin-bottom: 0px;
  font-weight: 500;
}

.post-comments .comment-author a {
  color: #dc2b1c;
  font-size: 14px;
  text-transform: uppercase;
}

.post-comments time {
  margin: 0 0 5px;
  display: inline-block;
  color: #808080;
  font-size: 12px;
}

.post-comments .comment-button {
  color: #dc2b1c;
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
}

.post-comments .comment-button i {
  margin-right: 5px;
  display: inline-block;
}

.post-comments .comment-button:hover {
  color: #dc2b1c;
}

.post-excerpt {
  margin-bottom: 60px;
}

.post-excerpt h3 a {
  color: #000;
}

.post-excerpt p {
  margin: 0 0 30px;
}

.post-excerpt blockquote.quote-post {
  margin: 20px 0;
}

.post-excerpt blockquote.quote-post p {
  line-height: 30px;
  font-size: 20px;
  color: #dc2b1c;
}

.single-blog {
  background-color: #fff;
  margin-bottom: 50px;
  padding: 20px;
}

.blog-subtitle {
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.next-prev {
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  margin: 20px 0;
  padding: 25px 0;
}

.next-prev a {
  color: #000;
}

.next-prev a:hover {
  color: #dc2b1c;
}

.next-prev .prev-post i {
  margin-right: 10px;
}

.next-prev .next-post i {
  margin-left: 10px;
}

.social-profile ul li {
  margin: 0 10px 0 0;
  display: inline-block;
}

.social-profile ul li a {
  color: #4e595f;
  display: block;
  font-size: 16px;
}

.social-profile ul li a i:hover {
  color: #dc2b1c;
}

.comments-section {
  margin-top: 35px;
}

.author-about {
  margin-top: 40px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list ul li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-right: 10px;
}

.comment-wrap .media .media-heading {
  font-size: 14px;
  margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
  color: #dc2b1c;
  font-size: 13px;
}

.comment-wrap .media .comment-meta {
  font-size: 12px;
  color: #888;
}

.comment-wrap .media p {
  margin-top: 15px;
}

.comment-reply-form {
  margin-top: 80px;
}

.comment-reply-form input, .comment-reply-form textarea {
  height: 35px;
  border-radius: 0;
  box-shadow: none;
}

.comment-reply-form input:focus, .comment-reply-form textarea:focus {
  box-shadow: none;
  border: 1px solid #dc2b1c;
}

.comment-reply-form textarea, .comment-reply-form .btn-main, .comment-reply-form .btn-transparent, .comment-reply-form .btn-small {
  height: auto;
}

.widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
}

.widget .widget-title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid #dedede;
}

.widget.widget-latest-post .media .media-object {
  width: 100px;
  height: auto;
}

.widget.widget-latest-post .media .media-heading a {
  color: #242424;
  font-size: 16px;
}

.widget.widget-latest-post .media p {
  font-size: 12px;
  color: #808080;
}

.widget.widget-category ul li {
  margin-bottom: 10px;
}

.widget.widget-category ul li a {
  color: #837f7e;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.widget.widget-category ul li a:before {
  padding-right: 10px;
}

.widget.widget-category ul li a:hover {
  color: #dc2b1c;
  padding-left: 5px;
}

.widget.widget-tag ul li {
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 5px;
}

.widget.widget-tag ul li a {
  color: #837f7e;
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #dedede;
  border-radius: 30px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.widget.widget-tag ul li a:hover {
  color: #fff;
  background: #dc2b1c;
  border: 1px solid #dc2b1c;
}

.footer {
  padding-bottom: 10px;
}

.footer .copyright a {
  font-weight: 600;
}

.lh-35 {
  line-height: 35px;
}

.logo {
  color: #242424;
  font-weight: 600;
  letter-spacing: 1px;
}

.logo span {
  color: #dc2b1c;
}

.sub-form {
  position: relative;
}

.sub-form .form-control {
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #f5f8f9;
}

.footer-btm {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.footer-socials li a {
  margin-left: 15px;
}

/*=== MEDIA QUERY ===*/
@media (max-width: 400px) {
  .header-top .header-top-info a {
    margin-left: 0px;
    display: block;
  }
  .navbar-toggler {
    color: #fff;
  }
  .slider .block h1 {
    font-size: 28px;
    line-height: 40px;
  }
  .slider {
    padding: 126px 0 151px 0;
  }
  .content-title {
    font-size: 28px;
    line-height: 46px;
  }
  .bg-about {
    display: none;
  }
  .p-5 {
    padding: 2rem !important;
  }
  h2, .h2 {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 36px;
  }
  .testimonial-item .testimonial-item-content {
    padding-left: 0px;
    padding-top: 30px;
  }
  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
    margin-left: 0px;
  }
  .text-lg {
    font-size: 3rem;
  }
  .header-top .header-top-info {
    padding-bottom: 15px;
  }
  .blog-item-meta span {
    margin: 6px 0px;
  }
  .widget {
    margin-bottom: 30px;
    padding-bottom: 0px;
  }
  .dropdown-menu {
    display: none;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .header-top .header-top-info a {
    margin-left: 0px;
  }
  .navbar-toggler {
    color: #fff;
  }
  .slider .block h1 {
    font-size: 38px;
    line-height: 50px;
  }
  .slider {
    padding: 126px 0 151px 0;
  }
  .content-title {
    font-size: 28px;
    line-height: 46px;
  }
  .bg-about {
    display: none;
  }
  .p-5 {
    padding: 2rem !important;
  }
  h2, .h2 {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 36px;
  }
  .testimonial-item .testimonial-item-content {
    padding-left: 0px;
    padding-top: 30px;
  }
  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
    margin-left: 0px;
  }
  .blog-item-meta span {
    display: block;
    margin: 6px 0px;
  }
  .widget {
    margin-bottom: 30px;
    padding-bottom: 0px;
  }
  .dropdown-menu {
    display: none;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .navbar-toggler {
    color: #fff;
  }
  .bg-about {
    display: none;
  }
  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
    margin-left: 0px;
  }
  .slider .block h1 {
    font-size: 56px;
    line-height: 70px;
  }
  .blog-item-meta span {
    display: block;
    margin: 6px 0px;
  }
  .widget {
    margin-bottom: 30px;
    padding-bottom: 0px;
  }
  .dropdown-menu {
    display: none;
    width: 100%;
    text-align: center;
  }
}
